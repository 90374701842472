import React, { useState, useEffect } from "react";
import "../../../assets/styles/pages/raceplaces/raceplaces.scss";
import gross from "../../../assets/images/raceinfo/gross.png";
import KanhaRunService from "../../services/kanha.service";
import { navigate } from "gatsby";
import Breadcrumbsbanner from "../breadcumbbanner/breadcumbsbanner";
import config from "../../../assets/config";
import { lStorage } from "../../utils/storage";

function RacePlaces() {
  const [hoveredState, setHoveredState] = useState(null);
  const [countryList, setCountryList] = useState([]);
  const existLocation = lStorage.get("selectedLocation");

  const kanhaApi = new KanhaRunService();

  function handleStateHover(stateName) {
    setHoveredState(stateName);
  }

  function handleStateLeave() {
    setHoveredState(null);
  }

  function handleCityClick(cityDetails) {
    if (cityDetails.city_id === 1 && cityDetails.country === "India") {
      cityDetails.runType = "Timed Run";
    } else if (cityDetails.city_id !== 1 && cityDetails.country === "India") {
      cityDetails.runType = "Global Run";
    } else {
      cityDetails.runType = "Overseas";
    }
    try {
      if(existLocation?.country === cityDetails.country && existLocation?.state_id === cityDetails.state_id && existLocation?.city_id === cityDetails.city_id){
        localStorage.setItem("selectedLocation", JSON.stringify(cityDetails));
      }else{
        localStorage.setItem("selectedLocation", JSON.stringify(cityDetails));
        lStorage.remove("list");
      }
      
    } catch (error) {
      console.error("Error storing data in localStorage:", error);
    }
    navigate("/raceprice");
  }

  useEffect(() => {
    kanhaApi
      .getcountrylist()
      .then((response) => {
        if (
          response.data.isError === false ||
          response.data.statusCode === "200"
        ) {
          if (response.data.isError === false) {
            setCountryList(response.data.data);
          } else {
            console.log("error");
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  return (
    <>
    {/* <Breadcrumbsbanner /> */}
    <div className="container">
      <div className="row">
        <div className="my-4 text-center">
        <h3 className="title-heading">RACE LOCATIONS</h3>
        </div>
        <div className="col-md-6 my-5">
          <div className="race-title">
            <h3>Select the State (City) where you want to run</h3>
          </div>
          {countryList.map((country) => {
            if (country.Country_name === "India") {
              return (
                <div className="state-buttons" key={country.Country_name}>
                  {country.states
                       .sort((a, b) => {
                        if (a.state_name === "Telangana") return -1;
                        if (b.state_name === "Telangana") return 1;
                        return a.state_name.localeCompare(b.state_name);
                      })
                    .map((state) => (
                      <div
                        key={state.state_name}
                        className={`state-button 
                          ${hoveredState === state.state_name ? "active" : ""}
                          ${(state.state_name === "Telangana" && config.enableKanhaReg === 1)? "telangana-class" : ""}
                          ${(state.state_name !== "Telangana" && config.enableGlobalReg === 1) ? "telangana-class" : ""}`}
                          onTouchStart={(e) => {
                            e.preventDefault();
                            if ((state.state_name === "Telangana" && config.enableKanhaReg === 1)) {
                              handleStateHover(state.state_name);
                            }
                            if ((state.state_name !== "Telangana" && config.enableGlobalReg === 1)) {
                              handleStateHover(state.state_name);
                            }
                          }}
                        onMouseEnter={() => {
                          if ((state.state_name === "Telangana" && config.enableKanhaReg === 1) ) {
                            handleStateHover(state.state_name);
                          }
                          if ((state.state_name !== "Telangana" && config.enableGlobalReg === 1)){
                            handleStateHover(state.state_name);
                          }
                        }}
                        onMouseLeave={() => {
                          if (state.state_name === "Telangana") {
                            handleStateLeave();
                          }
                          if (config.enableGlobalReg === 1){
                            handleStateLeave();
                          }
                        }}
                      >
                        {state?.state_name} 
                        {state.cities?.filter(city => city.city_status === 1).length > 0 && (" +")}
                        {hoveredState === state?.state_name && (
                          <div className="city-names">
                            {state?.cities.filter(city => city.city_status === 1).map((city) => (
                              <div
                                key={city.city_name}
                                className="city-name"
                                onClick={() =>{
                                  // if (state.state_name === "Telangana" || state.state_name === "Tamil Nadu") {
                                  handleCityClick({
                                    country: country.Country_name,
                                    country_id: country.Country_id,
                                    state: state.state_name,
                                    state_id: state.state_id,
                                    city: city.city_name,
                                    city_id: city.city_id,
                                  })
                                // }
                                }
                                }
                              >
                                {city.city_name}
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    ))}
                </div>
              );
            } else {
              return null; // Render nothing for non-Indian countries
            }
          })}
        </div>
        <div className="col-md-6 my-5">
          <div className="race-title">
            <h3>International</h3>
          </div>
          {countryList.map((country) => {
            if (country.Country_name !== "India") {
              return (
                <div className="state-buttons justify-content-center" key={country.Country_name}>
                  {country.states
                    .sort((a, b) => a.state_name.localeCompare(b.state_name))
                    .map((state) => (
                      <div
                        key={state.state_name}
                        className={`state-button${
                          hoveredState === state.state_name ? "active" : ""}
                          ${config.enableOverseasReg === 1 ? "telangana-class":""}`}
                          
                        onClick={() => {
                          if(config.enableOverseasReg === 1){
                            handleCityClick({
                              country: country.Country_name,
                              country_id: country.Country_id,
                              state: state.state_name,
                              state_id: state.state_id,
                              city: "",
                              city_id: "",
                            })
                          }
                        }
                        }
                      >
                        {state.state_name}
                      </div>
                    ))}
                </div>
              );
            } else {
              return null; // Render nothing for Indian states in this section
            }
          })}
        </div>
      </div>

      <div className="row my-4">
        <div className="gross-top-set">
          <img className="img-fluid" src={gross} alt="gross" />
        </div>
      </div>
    </div>
    </>
  );
}

export default RacePlaces;
